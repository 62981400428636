
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING
  //baseUrl = 'https://api.roca.bigcityvoucher.co.in/v1';

  //PROD
  // baseUrl ='https://hubadminapi.redemptionportalrbppl.in/v1';

  //baseUrl='http://192.168.1.178/stashfinfullfilment/web/v1';

  //baseUrl="http://192.168.1.178/stashfin_webappapi/web/v2";
 // baseUrl="https://api.stashfin.bigcityvoucher.co.in/v2"; 

 baseUrl="https://api.stashfin.bigcityexperience.com/v2";

 //baseUrl="https://api.stashfin.bigcityexperience.com/v2";


 // login = this.baseUrl + "/hubadmin/log-in";

  login = this.baseUrl + "/login/login";

  getListProgram = this.baseUrl + "/hubadmin/list-programs";
  getAuthrizedOrderList = this.baseUrl + "/redemption/list-authentication-orders?";
  approveRejectOrder = this.baseUrl + "/redemption/update-client-status";
  orderListStats = this.baseUrl + "/redemption/statistics"
  getRejectionReason = this.baseUrl + '/redemption/rejection-reason'
  dashboardDetails = this.baseUrl + '/client/dashboard'


  //reports
  redemptionReport = this.baseUrl + '/report/redemption-report'
  catalogueReport = this.baseUrl + '/report/sku-report'
  loginReport = this.baseUrl + '/report/login-report'
  cancellationReport = this.baseUrl + '/report/cancellation-report'
  pointBalanceReport = this.baseUrl + '/report/pointbalance-report'
  deductionReport = this.baseUrl + '/report/deduction-report'

  //Finance
  getPerformaInvoice = this.baseUrl + "/client/getperforma?";
  approveSingleInvoice = this.baseUrl + "/client/setapprovalstatus?new_approval_status=2";
  downloadSingleInvoice = this.baseUrl + "/finance/proforma-download?";
  getInvoiceList = this.baseUrl + "/hubadmin/list-invoice?";
  uploadInvoiceStatus = this.baseUrl + "/client/setpaymentstatus";
}
export interface ApiConfigurationInterface { }